import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.override.style';

/** @namespace myApp/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    renderOldPrice() {
        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted
                } = {}
            } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount,
            isPdp
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        if (isPdp) {
            return (
                <del
                  block="ProductPrice"
                  elem="HighPrice"
                  aria-label={ __('Old product price') }
                  itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
                >
                    { originalPriceFormatted }
                </del>
            );
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
            >
                { originalPriceFormatted }
            </del>
        );
    }
}

export default ProductPriceComponent;
