/* eslint-disable max-len */
import ProductAttributeValue from 'Component/ProductAttributeValue';
import SizeGuideButton from 'Component/SizeGuideButton';
import { ProductConfigurableAttributes as SourceProductConfigurableAttributes } from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.override.style';

/** @namespace myApp/Component/ProductConfigurableAttributes/Component */
export class ProductConfigurableAttributesComponent extends SourceProductConfigurableAttributes {
    renderConfigurableAttributeValue(attribute) {
        const {
            getIsConfigurableAttributeAvailable,
            handleOptionClick,
            getLink,
            isSelected,
            showProductAttributeAsLink,
            handleOptionMouseEnter,
            handleOptionMouseLeave,
            inStock,
            mix,
            productVariantsColorImages
        } = this.props;

        const { attribute_value, attribute_code } = attribute;

        if (attribute_code === 'color') {
            return (
                <ProductAttributeValue
                  key={ attribute_value }
                  attribute={ attribute }
                  isSelected={ isSelected(attribute) }
                  isAvailable={ getIsConfigurableAttributeAvailable(attribute) && inStock }
                  onClick={ handleOptionClick }
                  getLink={ getLink }
                  showProductAttributeAsLink={ showProductAttributeAsLink }
                  onMouseEnter={ handleOptionMouseEnter }
                  onMouseLeave={ handleOptionMouseLeave }
                  swatchImages={ productVariantsColorImages }
                  mix={ { mix } }
                />
            );
        }

        return (
            <ProductAttributeValue
              key={ attribute_value }
              attribute={ attribute }
              isSelected={ isSelected(attribute) }
              isAvailable={ getIsConfigurableAttributeAvailable(attribute) && inStock }
              onClick={ handleOptionClick }
              getLink={ getLink }
              showProductAttributeAsLink={ showProductAttributeAsLink }
              productVariantsColorImages={ productVariantsColorImages }
              mix={ { mix } }

            />
        );
    }

    renderSwatchOnPLP(option) {
        const { attribute_values, attribute_code } = option;
        if (attribute_code === 'color') {
            const values_slice = 3;
            // eslint-disable-next-line max-len
            const more_values_count = attribute_values.length > values_slice ? attribute_values.length - values_slice : 0;

            return (
                <div block="ProductConfigurableAttributes" elem="SwatchList" key={ attribute_code }>
                    { attribute_values
                        .slice(0, values_slice)
                        .map((attribute_value) => this.renderConfigurableAttributeValue({ ...option, attribute_value })) }
                    { more_values_count > 0 && (
                        <span block="More" elem="Swatch">
                            { `+${more_values_count} More` }
                        </span>
                    ) }
                </div>
            );
        }

        return (
            <div block="ProductConfigurableAttributes" elem="SwatchList" key={ attribute_code }>
                { attribute_values.map((attribute_value) => this.renderConfigurableAttributeValue({ ...option, attribute_value })) }
            </div>
        );
    }

    renderSizeHelpText() {
        const { product: { color_swatch_square } } = this.props;

        if (color_swatch_square === 1) {
            return (
                <p>The sizes mentioned are US sizes. For unisex shoes, Men & Women sizes are respectively mentioned separated by a slash.</p>
            );
        }

        return null;
    }

    renderConfigurableAttributes() {
        const {
            configurable_options, isExpandable, inStock, parameters, isReady
        } = this.props;

        return Object.values(configurable_options).map((option) => {
            const {
                attribute_code, attribute_options, attribute_id
            } = option;

            const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
            const isSwatch = !!swatch_data;

            // render content without heading and subheading
            if (!isExpandable) {
                return isSwatch ? this.renderSwatchOnPLP(option) : this.renderDropdown(option);
            }

            if (!inStock && !isSwatch) {
                return null;
            }

            const selectedOption = parameters[attribute_code];
            const selectedOptionLabel = selectedOption ? attribute_options[selectedOption]?.label : '';

            return (
                <div key={ attribute_id }>
                    { attribute_code === 'size' && <SizeGuideButton isReady={ isReady } /> }
                    { attribute_code === 'color' && (
                        <p block="ProductConfigurableAttributes" elem="Title">
                        { isSwatch && (
                            <span block="ProductConfigurableAttributes" elem="SelectedOptionLabel">
                                { selectedOptionLabel }
                            </span>
                        ) }
                        </p>
                    ) }
                    { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                    { isSwatch ? this.renderSwatch(option) : this.renderDropdown(option) }
                    { /* { attribute_code === 'size' && this.renderSizeHelpText() } */ }
                </div>
            );
        });
    }
}

export default ProductConfigurableAttributesComponent;
