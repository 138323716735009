/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { handleGAEventsTracker } from 'Util/Helper';
import history from 'Util/History';
import { getQueryParam } from 'Util/Url';

import {
    GIFT_CARD,
    PRODUCT_VIEWED
} from '../config';
import { getAttribute, getProductImg } from '../helper/Product';
import { eventTrack } from '../helper/WebEngage/Event';

export const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const [prevProps] = args;

    const {
        product,
        product: {
            sku, attributes
        }
    } = instance.props;

    const {
        product: {
            sku: prevSku
        }
    } = prevProps;

    if (sku !== prevSku) {
        const {
            name, type_id, gender,
            price_range: {
                minimum_price: {
                    final_price: { value, currency }
                }
            },
            media_gallery_entries
        } = product;

        const COLOR = 'color';
        const colorValue = getQueryParam(COLOR, history.location);
        const [colorData] = Object.values(attributes).filter((item) => item.attribute_code === COLOR);
        const color = (colorData && colorValue) ? colorData.attribute_options[colorValue]?.label : '';

        // const img = Object.values(media_gallery_entries).map(({ base: { url } }) => url);

        if (type_id === 'mageworx_giftcards') {
            eventTrack(GIFT_CARD, {
                'Card Name': name
            });
            handleGAEventsTracker('event', 'view_item', {
                currency: 'INR',
                value,
                items: [{
                    item_id: sku,
                    item_name: name,
                    price: value,
                    quantity: 1
                }]
            });
        } else {
            eventTrack(PRODUCT_VIEWED, {
                'Product Name': name,
                Price: value,
                Color: color,
                Gender: gender || '',
                'Product Image': getProductImg(media_gallery_entries),
                'Product SKU': sku
            });
            handleGAEventsTracker('event', 'view_item', {
                currency,
                value,
                items: [{
                    item_id: sku,
                    item_name: name,
                    index: 1,
                    currency,
                    price: value,
                    quantity: 1
                }]
            });
        }
    }
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};
