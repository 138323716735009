import { Field } from 'Util/Query';

/** @namespace GiftCard/Query/GiftCard/Query */
export class GiftCardQuery {
    getQuery(input) {
        return new Field('addGiftCardProductsToCart')
            .addArgument('input', 'AddGiftCardProductsToCartInput!', input)
            .addField(
                new Field('cart')
                    .addField(
                        new Field('items')
                            .addFieldList([
                                new Field('product')
                                    .addFieldList(['name', 'sku'])
                            ])
                    )
            );
    }

    applyGiftQuery(input) {
        return new Field('applyMwGiftCardToCart')
            .addArgument('input', 'ApplyMwGiftCardToCartInput!', input)
            .addField(
                new Field('cart')
                    .addField(
                        new Field('applied_mw_gift_cards')
                            .addFieldList([
                                'code',
                                new Field('remaining')
                                    .addFieldList(['value', 'currency_code', 'label']),
                                new Field('applied')
                                    .addFieldList(['value', 'currency_code', 'label'])
                            ])
                    )
            );
    }

    getCartGiftCardInfoQuery(input) {
        return new Field('CartGiftCardInfo')
            .addArgument('input', 'CartGiftCardInfoInput!', input)
            .addFieldList([
                'code',
                'status',
                new Field('applied')
                    .addFieldList(['value', 'currency_code', 'label']),
                new Field('remaining')
                    .addFieldList(['value', 'currency_code', 'label']),
                'valid_till'
            ]);
    }

    giftCardStatus(code) {
        return new Field('mwGiftCardInfo')
            .addArgument('code', 'String!', code)
            .addFieldList([
                'status',
                'valid_till',
                new Field('balance')
                    .addFieldList(['value', 'currency_code', 'label'])
            ]);
    }

    removeAppliedGiftCard(input) {
        return new Field('removeMwGiftCardFromCart')
            .addArgument('input', 'RemoveMwGiftCardToCartInput', input)
            .addField(
                new Field('cart')
                    .addField(
                        new Field('applied_mw_gift_cards')
                            .addFieldList([
                                'code',
                                new Field('remaining')
                                    .addFieldList(['value', 'currency_code', 'label']),
                                new Field('applied')
                                    .addFieldList(['value', 'currency_code', 'label'])
                            ])
                    )
            );
    }
}

export default new GiftCardQuery();
