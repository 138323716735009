/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import PropTypes from 'prop-types';

import { ProductAttributeValue as SourceProductAttributeValue } from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';
import { MixType } from 'Type/Common.type';
import { AttributeType } from 'Type/ProductList.type';
import { noopFn } from 'Util/Common';

import './ProductAttributeValue.override.style';

/** @namespace myApp/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    static propTypes = {
        getLink: PropTypes.func,
        onClick: PropTypes.func,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        attribute: AttributeType.isRequired,
        isSelected: PropTypes.bool,
        isAvailable: PropTypes.bool,
        mix: MixType,
        isFormattedAsText: PropTypes.bool,
        isProductCountVisible: PropTypes.bool,
        showProductAttributeAsLink: PropTypes.bool,
        swatchImages: PropTypes.objectOf()
    };

    static defaultProps = {
        isSelected: false,
        onClick: noopFn,
        onMouseEnter: noopFn,
        onMouseLeave: noopFn,
        getLink: noopFn,
        mix: {},
        isAvailable: true,
        isFormattedAsText: false,
        isProductCountVisible: false,
        showProductAttributeAsLink: true,
        swatchImages: {}
    };

    onMouseEnterHandler = this.onMouseEnterHandler.bind(this);

    onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);

    onMouseEnterHandler(e) {
        e.preventDefault();
        e.stopPropagation();

        const {
            attribute: { attribute_code, attribute_value },
            onMouseEnter
        } = this.props;

        onMouseEnter({ attribute_code, attribute_value });
    }

    onMouseLeaveHandler(e) {
        e.preventDefault();
        e.stopPropagation();

        const { onMouseLeave } = this.props;

        onMouseLeave();
    }

    getCheckboxLabel(value, _subLabel) {
        return (
            <span block="ProductAttributeValue" elem="Label">
                { value }
            </span>
        );
    }

    renderColorValue(color, label) {
        const { isFormattedAsText, isSelected } = this.props;
        const isLight = this.getIsColorLight(color);

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const {
            attribute: { attribute_value },
            swatchImages
        } = this.props;

        if (swatchImages[attribute_value]) {
            return this.renderCustomImageValue(swatchImages[attribute_value], label);
        }

        const style = {
            '--option-background-color': color,
            '--option-border-color': isLight ? '#dddddd' : color,
            '--option-check-mark-background': isLight ? '#000' : '#fff',
            // stylelint-disable-next-line value-keyword-case
            '--option-is-selected': isSelected ? 1 : 0
        };

        return (
            <data
              block="ProductAttributeValue"
              elem="Color"
              value={ label }
              title={ `Select Color ${label}` }
              style={ style }
            />
        );
    }

    renderImageValue(img, label) {
        const { isFormattedAsText, isSelected } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const {
            attribute: { attribute_value },
            swatchImages
        } = this.props;

        if (swatchImages[attribute_value]) {
            return this.renderCustomImageValue(swatchImages[attribute_value], label);
        }

        const style = {
            // stylelint-disable-next-line value-keyword-case
            '--option-is-selected': isSelected ? 1 : 0
        };

        return (
            <>
                <img
                  block="ProductAttributeValue"
                  elem="Image"
                  src={ `/media/attribute/swatch${img}` }
                  alt={ label }
                />
                <data
                  block="ProductAttributeValue"
                  elem="Image-Overlay"
                  value={ label }
                  title={ label }
                  style={ style }
                />
            </>
        );
    }

    renderCustomImageValue(img, label) {
        const { isFormattedAsText, isSelected } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            // stylelint-disable-next-line value-keyword-case
            '--option-is-selected': isSelected ? 1 : 0
        };

        return (
            <span block="ProductAttributeValue" elem="IsSquare">
                <img
                  block="ProductAttributeValue"
                  elem="Image"
                  src={ `${img.url}` }
                  alt={ label }
                />
                <data
                  block="ProductAttributeValue"
                  elem="Image-Overlay"
                  value={ label }
                  title={ label }
                  style={ style }
                />
            </span>
        );
    }

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);

        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;
        if (isFormattedAsText) {
            return (
                <div block="ProductAttributeValue" mix={ mix }>
                    { this.renderAttributeByType() }
                </div>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable } }
                  onClick={ this.clickHandler }
                  onKeyDown={ this.clickHandler }
                  role="link"
                  tabIndex="-1"
                  aria-hidden={ isNotAvailable }
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </span>
            );
        }

        return (
            <a
              href={ href }
              block="ProductAttributeValue"
              mods={ { isNotAvailable } }
              onClick={ this.clickHandler }
              onMouseEnter={ this.onMouseEnterHandler }
              onMouseLeave={ this.onMouseLeaveHandler }
              aria-hidden={ isNotAvailable }
              mix={ mix }
            //   className={ attribute_code }
            >
                { this.renderAttributeByType() }
            </a>
        );
    }
}

export default ProductAttributeValueComponent;
