import { ProductConfigurableAttributesContainer as SourceProductConfigurableAttributesContainer }
    from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import { noopFn } from 'Util/Common';

/** @namespace myApp/Component/ProductConfigurableAttributes/Container */
export class ProductConfigurableAttributesContainer extends SourceProductConfigurableAttributesContainer {
    static defaultProps = {
        ...SourceProductConfigurableAttributesContainer.defaultProps,
        updateConfigurableVariantMouseEvent: noopFn
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleOptionMouseEnter: this.handleOptionMouseEnter.bind(this),
        handleOptionMouseLeave: this.handleOptionMouseLeave.bind(this)
    };

    containerProps() {
        const {
            productVariantsColorImages,
            product
        } = this.props;

        return {
            ...super.containerProps(),
            productVariantsColorImages,
            product
        };
    }

    handleOptionMouseEnter({ attribute_code, attribute_value }) {
        const { updateConfigurableVariantMouseEvent } = this.props;
        updateConfigurableVariantMouseEvent({ attribute_code, attribute_value });
    }

    handleOptionMouseLeave() {
        const { updateConfigurableVariantMouseEvent } = this.props;
        updateConfigurableVariantMouseEvent(null);
    }
}

export default ProductConfigurableAttributesContainer;
