import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import OrderQuery from 'Query/Order.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import { REMOVE_CANCEL_ORDER_ITEM_POPUP_ID } from '../MyAccountOrderItemsTableRow/MyAccountOrderItemsTableRow.config';
import CancelOrderPopup from './CancelOrderPopup.component';
/** @namespace myApp/Component/CancelOrderPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload[REMOVE_CANCEL_ORDER_ITEM_POPUP_ID] || ''
});
/** @namespace myApp/Component/CancelOrderPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (error) => dispatch(showNotification('error', getErrorMessage(error))),
    hidePopup: () => dispatch(showPopup('', {})),
    showNotification(type, message) {
        dispatch(showNotification(type, message));
    }
});
/** @namespace myApp/Component/CancelOrderPopup/Container */
export class CancelOrderPopupContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        hidePopup: PropTypes.func.isRequired,
        afterCancel: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        payload: PropTypes.isRequired

    };

    state = {
        isLoading: false
    };

    containerFunctions = {
        cancelOrderItemConfirm: this.cancelOrderItemConfirm.bind(this),
        onCloseOrderClick: this.onCloseOrderClick.bind(this)
    };

    onCloseOrderClick() {
        const { hidePopup } = this.props;
        hidePopup();
    }

    async cancelOrderItemConfirm() {
        const {
            payload, afterCancel, hidePopup, showErrorNotification, showNotification
        } = this.props;

        try {
            const orderItem = {
                orderId: payload.id,
                itemId: payload.order_item_id,
                qty: payload.quantity_ordered
            };
            const mutation = OrderQuery.getOrderItemCancelQuery(orderItem);
            const response = await fetchMutation(mutation);
            hidePopup();
            showNotification('success', response.CancelItem.message);
            afterCancel();
        } catch (error) {
            hidePopup();
            showErrorNotification(error);
        }
    }

    containerProps() {
        const { isLoading } = this.state;
        const { payload } = this.props;
        return {
            isLoading,
            payload
        };
    }

    render() {
        return (
            <CancelOrderPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CancelOrderPopupContainer);
