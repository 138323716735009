/* eslint-disable max-len */
/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { STATE_CONFIRM_EMAIL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import OtpQuery from 'Query/OTP.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccountContainer
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';
import { showPopup } from 'Store/Popup/Popup.action';
import { SignInStateType } from 'Type/Account.type';
import transformToNameValuePair from 'Util/Form/Transform';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';

import MyAccountCreateAccount from './MyAccountCreateAccount.component';
import {
    CONFIRMATION_REQUIRED,
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_VERIFY_OTP,
    SHOW_VAT_NUMBER_REQUIRED
} from './MyAccountCreateAccount.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace myApp/Component/MyAccountCreateAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace myApp/Component/MyAccountCreateAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    hidePopup: () => dispatch(showPopup('', {})),
    createAccountVerify: (options) => MyAccountDispatcher
        .then(({ default: dispatcher }) => dispatcher.createAccountVerify(options, dispatch))
    // TODO extend mapDispatchToProps
});

/** @namespace myApp/Component/MyAccountCreateAccount/Container */
export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccountContainer {
    static propTypes = {
        prepone: PropTypes.string,
        createAccount: PropTypes.func.isRequired,
        onSignIn: PropTypes.func.isRequired,
        setSignInState: PropTypes.func.isRequired,
        setLoadingState: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        showTaxVatNumber: PropTypes.bool.isRequired,
        isLandingPage: PropTypes.bool,
        isMobile: PropTypes.bool.isRequired,
        handleSignIn: PropTypes.func.isRequired,
        state: SignInStateType.isRequired,
        newsletterActive: PropTypes.bool.isRequired
    };

    containerFunctions = {
        // onCreateAccountAttempt: this.onCreateAccountAttempt.bind(this),
        handleCreateAccountOTP: this.handleCreateAccountOTP.bind(this),
        handleCreateAccountOTPVerify: this.handleCreateAccountOTPVerify.bind(this),
        handleResendOTP: this.handleResendOTP.bind(this),
        handleRetry: this.handleRetry.bind(this)
    };

    state = {
        currentCreateRenderForm: CREATE_ACCOUNT,
        formFields: {},
        count: 0,
        resendCount: 0,
        isLoading: false
        // isRetryForm: false
    };

    containerProps = () => {
        const {
            prepone, state, handleSignIn, showTaxVatNumber, newsletterActive, hidePopup
        } = this.props;

        // const { currentCreateRenderForm, formFields, isRetryForm } = this.state;
        const { currentCreateRenderForm, formFields, isLoading } = this.state;

        return {
            prepone,
            state,
            handleSignIn,
            showTaxVatNumber,
            newsletterActive,
            currentCreateRenderForm,
            formFields,
            hidePopup,
            isLoading,
            // isRetryForm,
            vatNumberRequired: this.getVatNumberRequired()
        };
    };

    async handleCreateAccountOTP(form, fields) {
        // eslint-disable-next-line no-console

        const { showNotification } = this.props;

        this.setState({ isLoading: true });
        const transformFields = transformToNameValuePair(fields);

        try {
            const mutation = OtpQuery.getCreateAccountOTP(transformFields);
            await fetchMutation(mutation);

            this.setState({
                currentCreateRenderForm: CREATE_ACCOUNT_VERIFY_OTP,
                formFields: transformFields
            });
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }

        this.setState({ isLoading: false });
    }

    async handleCreateAccountOTPVerify(form, fields) {
        const {
            createAccountVerify,
            showNotification
            // onSignIn
        } = this.props;

        this.setState({ isLoading: true });
        const transformFieldsVerify = transformToNameValuePair(fields);

        try {
            // const mutation = OtpQuery.getCreateAccountOTPVerify(fields.mobileNumber, fields.otp);
            const mutation = OtpQuery.getCreateAccountOTPVerify(transformFieldsVerify);
            await fetchQuery(mutation);

            // eslint-disable-next-line no-unused-vars
            const { confirm_password, ...customerData } = transformFieldsVerify;
            await createAccountVerify(customerData);
            // onSignIn();
        } catch (error) {
            showNotification('error', getErrorMessage(error));

            this.setState(
                (prevState) => ({
                    count: prevState.count + 1
                }),
                () => {
                    const { count } = this.state;
                    // eslint-disable-next-line no-magic-numbers
                    if (count >= 3) {
                        this.setState({
                            currentCreateRenderForm: CREATE_ACCOUNT,
                            formFields: {},
                            count: 0
                        });
                    }
                }
            );
        }

        this.setState({ isLoading: false });
    }

    async handleResendOTP() {
        const { showNotification } = this.props;

        this.setState({ isLoading: true });
        const fields = this.state.formFields;
        try {
            const mutation = OtpQuery.getCreateAccountOTP(fields);
            await fetchMutation(mutation);

            this.setState({ count: 0 });

            this.setState(
                (prevState) => ({
                    resendCount: prevState.resendCount + 1
                }),
                () => {
                    const { resendCount } = this.state;
                    // eslint-disable-next-line no-magic-numbers
                    if (resendCount >= 3) {
                        this.setState({
                            // isRetryForm: true,
                            formFields: {},
                            resendCount: 0
                        });
                    }
                }
            );
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }

        this.setState({ isLoading: false });
    }

    handleRetry(e) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();

        this.setState({
            currentCreateRenderForm: CREATE_ACCOUNT,
            formFields: {},
            count: 0,
            resendCount: 0
        });
    }

    getVatNumberRequired() {
        const { showTaxVatNumber } = this.props;

        return showTaxVatNumber === SHOW_VAT_NUMBER_REQUIRED;
    }

    onError() {
        const { showNotification } = this.props;
        showNotification('info', __('Incorrect data! Please resolve all field validation errors.'));
    }

    async onSuccess(form, fields) {
        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            isLandingPage,
            showNotification,
            isMobile
        } = this.props;

        const {
            password, email, firstname, lastname, is_subscribed, taxvat
        } = transformToNameValuePair(fields);

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed,
                taxvat
            },
            password
        };

        if (isLoading) {
            return;
        }

        try {
            const code = await createAccount(customerData).catch(
                /** @namespace myApp/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/code/createAccount/catch */
                () => process.exit(1)
            );

            // if user needs confirmation
            if (code === CONFIRMATION_REQUIRED) {
                setSignInState(STATE_CONFIRM_EMAIL);

                if (isLandingPage || isMobile) {
                    showNotification(
                        'success',
                        // eslint-disable-next-line max-len
                        __(
                            'The email confirmation link has been sent to your email. Please confirm your account to proceed.'
                        )
                    );
                }
            } else if (code !== false) {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }
    }

    render() {
        return <MyAccountCreateAccount { ...this.containerProps() } { ...this.containerFunctions } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
