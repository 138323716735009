/* eslint-disable no-magic-numbers */
import getStore from 'Util/Store';

import { ADD_TO_CART, CART_UPDATED } from '../config';
import { getCartProductsDetailsInArray } from '../helper/Cart';
import { getProductImg, getSelectedAttributeAfterAddToCart } from '../helper/Product';
import { eventTrack } from '../helper/WebEngage/Event';

/** @namespace Ambab/AddtoCartPopup/Plugin/Product/Container/addToCart */
export const addToCart = async (args, callback, instance) => {
    if (await callback(...args)) {
        const {
            product: {
                name, attributes, gender,
                price_range: {
                    minimum_price: {
                        final_price: { value: price }
                    }
                },
                media_gallery_entries, sku
            }, parameters
        } = instance.props;

        const { quantity } = instance.state;

        const selectedOptionLabel = getSelectedAttributeAfterAddToCart(attributes, parameters);
        const [sizeOption] = selectedOptionLabel.filter((i) => i.size);
        const [colorOption] = selectedOptionLabel.filter((i) => i.color);

        eventTrack(ADD_TO_CART, {
            'Product Name': name,
            Size: sizeOption ? sizeOption.size : '',
            Color: colorOption ? colorOption.color : '',
            Quantity: quantity,
            Price: price,
            'Product Image': getProductImg(media_gallery_entries),
            Gender: gender || '',
            'Product SKU': sku
        });

        const state = getStore().getState();
        const cartData = state.CartReducer.cartTotals;
        const { items = [], subtotal } = cartData;

        eventTrack(CART_UPDATED, {
            'No. of Products': items.length,
            'Total Amount': subtotal,
            'Product Details': getCartProductsDetailsInArray(items)
        });

        return true;
    }

    return false;
};

export default {
    'Component/Product/Container': {
        'member-function': {
            addToCart
        }
    }
};
