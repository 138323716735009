import { handleGAEventsTracker } from 'Util/Helper';

import { ADD_TO_WISHLIST, ADD_TO_WISHLIST_GIFT_CARD, WISHLIST_UPDATED } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';
import { getWishlistProducts } from '../helper/Wishlist';

export const toggleProductInWishlist = (args, callback, instance) => {
    const [add] = args;

    return callback(...args).then(() => {
        const {
            productWishlist, product
        } = instance.props;

        const {
            sku, name,
            price_range: {
                minimum_price: {
                    final_price: { value }
                }
            }, small_image: { url }, type_id
        } = product || productWishlist;

        const { productsInWishlist } = instance.props;

        const wishlistTotal = Object.entries(productsInWishlist).reduce((acc, [, val]) => {
            const { price_range: { minimum_price: { final_price: { value } } } } = val;

            return acc + value;
        }, 0);

        const wishlistProduct = Object.entries(productsInWishlist).map(getWishlistProducts);

        if (add) {
            const [wishlistItem] = Object.values(productsInWishlist).filter((i) => i.sku === sku);
            const { wishlist: { options = [], sku: wishlistSku, quantity } } = wishlistItem || productWishlist;

            if (type_id === 'mageworx_giftcards') {
                eventTrack(ADD_TO_WISHLIST_GIFT_CARD, {
                    'Card Name': name
                });
                handleGAEventsTracker('event', 'add_to_wishlist', {
                    item_name: name
                });
            } else {
                eventTrack(ADD_TO_WISHLIST, {
                    Color: options.length > 0 && options[0] ? options[0].value : '',
                    Size: options.length > 0 && options[1] ? options[1].value : '',
                    'Product Name': name,
                    Price: value,
                    'Product Image': [url],
                    'Product SKU': wishlistSku
                });
                handleGAEventsTracker('event', 'add_to_wishlist', {
                    currency: 'INR',
                    value,
                    items: [{
                        item_id: wishlistSku,
                        item_name: name,
                        currency: 'INR',
                        price: value,
                        quantity
                    }]
                });
            }
        } else if (type_id === 'mageworx_giftcards') {
            handleGAEventsTracker('event', 'remove_from_wishlist', {
                item_name: name
            });
        } else {
            handleGAEventsTracker('event', 'remove_from_wishlist', {
                currency: 'INR',
                value,
                items: [{
                    item_id: sku,
                    item_name: name,
                    price: value
                }]
            });
        }

        eventTrack(WISHLIST_UPDATED, {
            'Total Amount': wishlistTotal,
            'No. Of Products': wishlistProduct.length,
            'Product Details': wishlistProduct
        });
    });
};

export default {
    'Component/ProductWishlistButton/Container': {
        'member-function': {
            toggleProductInWishlist
        }
    }
};
