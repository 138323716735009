/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const MyAccountReturnDetails = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ '../component/MyAccountReturnDetails'));

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const MyAccountNewReturn = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ '../component/MyAccountNewReturn'));

export const MyAccountGuestReturns = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ '../component/MyAccountGuestReturns'));

export const GuestReturn = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "track-order" */ '../route/GuestReturn'));

export const GUEST_RETURN_PAGE = 'GUEST_RETURN_PAGE';

export const RMA_DETAIL = 'rma_detail';

export const GUEST_RMA_LIST = 'guest_rma_list';

export const RMA_SINGLE_DETAIL = 'rma_single_detail';

export class RouterPlugin {
    switchItems = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/guest-returns/return-details/:entityId') } render={ (props) => <MyAccountReturnDetails { ...props } /> } />,
            position: 245,
            name: RMA_SINGLE_DETAIL
        },
        {
            component: <Route path={ withStoreRegex('/guest-return/new-return/:orderId') } render={ (props) => <MyAccountNewReturn { ...props } /> } />,
            position: 250,
            name: RMA_DETAIL
        },
        {
            component: <Route exact path={ withStoreRegex('/guest-return/rma/list') } render={ (props) => <MyAccountGuestReturns { ...props } /> } />,
            position: 255,
            name: GUEST_RMA_LIST
        },
        {
            component: <Route exact path={ withStoreRegex('/guest-return') } render={ (props) => <GuestReturn { ...props } /> } />,
            position: 260,
            name: GUEST_RETURN_PAGE
        }
    ];
}

const { switchItems } = new RouterPlugin();

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItems
        }
    }
};
