/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ReactGA from 'react-ga4';

import history from 'Util/History';
import getStore from 'Util/Store';

import './GoogleAdwords.style';

history.listen((location) => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
});

/** @namespace Ambab/Google_analytics/Component/GoogleAdwords/Component */
export class GoogleAdwordsComponent extends PureComponent {
    static propTypes = {
        history: PropTypes.objectOf.isRequired
    };

    componentDidMount() {
        const { google_adwords_account = null } = this.state;

        if (google_adwords_account) {
            ReactGA.initialize(google_adwords_account);
            this.hitPageView();
        }
    }

    componentDidUpdate(prevProps) {
        const { history: { location } } = this.props;
        const { history: { prevLocation } } = prevProps;

        if (location.pathname !== prevLocation.pathname) {
            this.hitPageView();
        }
    }

    __construct(props) {
        super.__construct(props);

        const state = getStore().getState();

        this.state = {
            google_adwords_account: `AW-${state.ConfigReducer.google_adwords_account}`
        };
    }

    hitPageView = () => {
        const { google_adwords_account = null } = this.state;

        if (google_adwords_account) {
            const { history: { location } } = this.props;
            ReactGA.send({ hitType: 'pageview', page: location.pathname });
        }
    };

    render() {
        return null;
    }
}

export default GoogleAdwordsComponent;
