import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer
} from 'SourceComponent/ProductPrice/ProductPrice.container';

import ProductPrice from './ProductPrice.component';

/** @namespace myApp/Component/ProductPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace myApp/Component/ProductPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace myApp/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {
    static propTypes = {
        ...this.propTypes,
        isPdp: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isPdp: false
    };

    containerProps() {
        const { isPdp } = this.props;

        return {
            ...super.containerProps(),
            isPdp
        };
    }

    render() {
        return (
            <ProductPrice
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
