import { Field } from 'Util/Query';

export const _genderField = () => {
    const gender = new Field('gender')
        .addArgument('fields', '[String]', 'gender')
        .setAlias('gender');

    return gender;
};

function _getCartProductInterfaceFields(args, callback, instance) {
    return [
        'uid',
        'id',
        'sku',
        'name',
        'type_id',
        'stock_status',
        'url',
        'salable_qty',
        'pdp_content',
        'size_chart_guide',
        _genderField(),
        instance._getStockItemField(),
        instance._getProductThumbnailField(),
        instance._getCartConfigurableProductFragment(),
        instance._getAttributesField(false, true),
        instance._getProductLinksField()
    ];
}

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _genderField, _getCartProductInterfaceFields
        }
    }
};
