/* eslint-disable no-unused-expressions */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import ApplyGiftCart from '../component/ApplyGiftCart';

export class CheckoutOrderSummaryComponentPlugin {
    _aroundRenderApplyGiftCard = (args, callback, instance) => {
        const { checkoutStep, getPaymentMethods } = instance.props;
        return (
            <>
            { callback(...args) }
            { checkoutStep === 'BILLING_STEP' && <ApplyGiftCart getPaymentMethods={ getPaymentMethods } /> }
            </>
        );
    };
}

const { _aroundRenderApplyGiftCard } = new CheckoutOrderSummaryComponentPlugin();

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderTotals: _aroundRenderApplyGiftCard
        }
    }
};
