import {
    OrderQuery as SourceOrderQuery
} from 'SourceQuery/Order.query';
import { Field } from 'Util/Query';

/** @namespace myApp/Query/Order/Query */
export class OrderQuery extends SourceOrderQuery {
    getOrderItemCancelQuery(order) {
        const { orderId, itemId, qty } = order;

        return new Field('CancelItem')
            .addArgument('orderId', 'Int!', orderId)
            .addArgument('itemId', 'Int!', itemId)
            .addArgument('qty', 'Int!', qty)
            .addFieldList(['message']);
    }

    getOrderCancelQuery(orderNumber) {
        return new Field('cancelOrder')
            .addArgument('orderNumber', 'String!', orderNumber)
            .addFieldList(['message']);
    }

    _getOrderItemsFields(isSingleOrder) {
        const basicFields = [
            'id',
            'increment_id',
            'order_date',
            'status',
            'can_reorder',
            'rss_link',
            this._getOrderItemTotalField(),
            this._getOrderItemsProductsFieldForListing()
        ];

        if (isSingleOrder) {
            return [...basicFields, ...this._getSingleOrderFields()];
        }

        return basicFields;
    }

    _getOrderItemsProductsFieldForListing() {
        return new Field('items')
            .addFieldList(this._getOrderItemProductsFieldsForListing());
    }

    _getOrderItemProductsFieldsForListing() {
        return [
            'product_url_key',
            'product_image'
        ];
    }

    _getOrderProductParameters() {
        return new Field('parameters')
            .addFieldList(['attribute_code', 'attribute_value', 'attribute_id']);
    }

    _getOrderItemProductsFields() {
        return [
            'product_url_key',
            'product_image',
            'product_url',
            'quantity_ordered',
            'quantity_shipped',
            'quantity_refunded',
            'quantity_canceled',
            'config_sku',
            'product_type',
            this._getOrderProductParameters(),
            this._getOrderProductEnteredOptionsField(),
            this._getOrderProductSelectedOptionsField(),
            this._getOrderProductRowSubtotalField(),
            ...this._getBaseOrderItemProductsFields()
        ];
    }
}

export default new OrderQuery();
