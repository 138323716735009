/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/jsx-no-bind */
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import ProductConfigurableAttributes from 'Component/ProductConfigurableAttributes';
import ProductPrice from 'Component/ProductPrice';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import { handleGAEventsTracker } from 'Util/Helper';

import fire from '../../style/icons/fire.svg';

import './ProductCard.override.style';
/** @namespace myApp/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    onMouseEnter = this.onMouseEnter.bind(this);

    onMouseLeave = this.onMouseLeave.bind(this);

    handleOptionClick = this.handleOptionClick.bind(this);

    registerSharedElement() {
        const {
            registerSharedElement, categoryUrl, quantity, product: {
                sku, name, price_range: {
                    minimum_price: { final_price: { value, currency } }
                }
            }
        } = this.props;

        const url = categoryUrl;

        const breadcrumb = url && url.replace(/.html/g, '');

        const bread = breadcrumb && breadcrumb.replaceAll('/', ' ').split(' ');

        registerSharedElement(this.imageRef);
        handleGAEventsTracker('event', 'select_item', {
            item_id: sku,
            item_name: name,
            index: 1,
            currency,
            item_category: bread && (bread[1] || null),
            item_category1: bread && (bread[2] || null),
            item_category2: bread && (bread[3] || null),
            item_category3: bread && (bread[4] || null),
            price: value,
            quantity
        });
    }

    renderProductTag() {
        const {
            product: { bestseller = false, newest = false }
        } = this.props;

        if (bestseller) {
            return (
                <span block="ProductCard" elem="Tag">
                    <span block="Tag" mix={ { block: 'Tag', elem: 'BestSeller' } }>
                        { __('Best Seller') }
                    </span>
                </span>
            );
        }
        if (newest) {
            return (
                <span block="ProductCard" elem="Tag">
                    <span block="Tag" mix={ { block: 'Tag', elem: 'New' } }>
                        <span block="Tag" elem="TagImage">
                            <Image src={ fire } />
                        </span>
                        { __('New') }
                    </span>
                </span>
            );
        }

        return null;
    }

    renderPrice(isPreview = false) {
        const { getActiveProduct, productPrice } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers
        } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  price={ productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                />
            </div>
        );
    }

    // mouse hover custom design ---
    renderPicture(mix = {}) {
        const {
            product: {
                id,
                type_id,
                small_image
            },
            product,
            hoverAttributeVariant,
            hoverAttributeConfiguable,
            plpColorSwatch
        } = this.props;

        // we commented these lines because we are not showing variant in plp page

        // const variant = variants && variants.length > 0 ? variants[0] : product;

        // const {
        //     small_image: image
        // } = variant;

        //

        const configurableProduct = plpColorSwatch && plpColorSwatch[0].attribute_options.length > 0 ? plpColorSwatch[0].attribute_options[0] : product;

        const { product_image, label } = configurableProduct;

        if (hoverAttributeConfiguable) {
            const { product_image, label } = hoverAttributeConfiguable;
            this.sharedComponent = (
                <Image
                  src={ `${product_image}` }
                  alt={ label }
                  ratio="custom"
                  mix={ { block: 'ProductCard', elem: 'Picture Picture_Front', mix } }
                  isPlaceholder={ !product_image }
                />
            );

            return (
                <>
                    { this.sharedComponent }
                    <img style={ { display: 'none' } } alt={ label } src={ `${product_image}` } />
                </>
            );
        }

        if (hoverAttributeVariant) {
            const { id: variantId, name: variantName, small_image: variantImage } = hoverAttributeVariant;

            this.sharedComponent = (
                <Image
                  src={ variantImage.url }
                  alt={ variantName }
                  ratio="custom"
                  mix={ { block: 'ProductCard', elem: 'Picture Picture_Front', mix } }
                  isPlaceholder={ !variantId }
                />
            );

            return (
                <>
                    { this.sharedComponent }
                    <img style={ { display: 'none' } } alt={ variantName } src={ variantImage.url } />
                </>
            );
        }

        if (type_id === 'mageworx_giftcards' || type_id === 'simple') {
            this.sharedComponent = (
                <Image
                  src={ small_image.url }
                  alt={ small_image }
                  ratio="custom"
                  mix={ { block: 'ProductCard', elem: 'Picture Picture_Front', mix } }
                  isPlaceholder={ !small_image }
                />
            );

            return (
                <>
                    { this.sharedComponent }
                    <img style={ { display: 'none' } } alt={ small_image } src={ small_image.url } />
                </>
            );
        }

        this.sharedComponent = (
            <>
                <Image
                  src={ product_image && `${product_image}` }
                  alt={ label }
                  ratio="custom"
                  mix={ { block: 'ProductCard', elem: 'Picture Picture_Front', mix } }
                  isPlaceholder={ !id }
                />
                <Image
                  src={ product_image && `${product_image}` }
                  alt={ label }
                  ratio="custom"
                  mix={ { block: 'ProductCard', elem: 'Picture Picture_Back', mix } }
                  isPlaceholder={ !id }
                />
            </>
        );

        return (
            <>
                { this.sharedComponent }
                <img style={ { display: 'none' } } alt={ label } src={ `${product_image}` } />
            </>
        );
    }

    // For variantproduct
    updateLinkWithColor(product, linkTo) {
        // eslint-disable-next-line no-prototype-builtins
        if (!product.hasOwnProperty('attributes')) {
            return linkTo;
        }

        const { product: { variants } } = this.props;

        const variant = (variants && variants[0]) || null;

        if (!variant) {
            return linkTo;
        }

        const { attributes } = variant;

        // eslint-disable-next-line fp/no-let
        const attributeOptions = ['color'];

        const queryString = Object.values(attributes)
            .filter((attribute) => attributeOptions.includes(attribute.attribute_code) && attribute.attribute_value)
            .map((attribute) => `${attribute.attribute_code}=${attribute.attribute_value}`).join('&');

        if (queryString) {
            return {
                ...linkTo,
                pathname: linkTo.pathname,
                search: queryString ? `?${queryString}` : ''
            };
        }

        return linkTo;
    }

    // For configurationProduct
    updateLinkWithColorForConfiguration(product, linkTo) {
        // eslint-disable-next-line no-prototype-builtins
        if (!product.hasOwnProperty('attributes')) {
            return linkTo;
        }

        const { plpColorSwatch } = this.props;

        const configurableProduct = (plpColorSwatch && plpColorSwatch[0].attribute_options[0]) || null;

        if (!configurableProduct) {
            return linkTo;
        }

        const queryString = [];
        const query = `color=${configurableProduct.value}`;
        // eslint-disable-next-line no-unused-vars
        const attributeValue = queryString.push(query);

        const queryStringForPlpProduct = queryString.join('&');

        if (queryStringForPlpProduct) {
            return {
                ...linkTo,
                pathname: linkTo.pathname,
                search: queryStringForPlpProduct ? `?${queryStringForPlpProduct}` : ''
            };
        }

        return linkTo;
    }

    renderCardLinkWrapper(children) {
        // const {
        //     linkTo, product: { url }, product
        // } = this.props;

        // // const updateLinkurl = this.updateLinkWithColor(product, linkTo);
        // // const updateLinkurlConfig = this.updateLinkWithColorForConfiguration(product, linkTo);

        return (
            <div
              block="ProductCard"
              elem="Link"
            >
                { children }
            </div>
        );
    }

    renderCardContent(mix = {}) {
        const { renderContent, linkTo, product } = this.props;
        const updateLinkurlConfig = this.updateLinkWithColorForConfiguration(product, linkTo);
        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper(
            <>
                <Link
                  block="ProductCard"
                  elem="Link"
                  to={ updateLinkurlConfig }
                  onClick={ this.registerSharedElement }
                  mix={ mix }
                >
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        { this.renderProductTag() }
                        { this.renderPicture() }
                        { this.renderProductCardWishlistButton() }
                    </figure>
                </div>
                </Link>
                <div block="ProductCard" elem="Content">
                    { this.renderConfigurableOptions() }
                    { this.renderSwatchList() }
                    { this.renderBrand() }
                    <Link
                      block="ProductCard"
                      elem="Link"
                      to={ updateLinkurlConfig }
                      onClick={ this.registerSharedElement }
                      mix={ mix }
                    >
                    { this.renderName(false) }
                    </Link>
                    { this.renderPrice() }
                </div>
            </>
        );
    }

    getIsColorLight(hex) {
        const color = (hex.charAt(0) === '#') ? hex.substring(1, 7) : hex;
        const r = parseInt(color.substring(0, 2), 16); // hexToR
        const g = parseInt(color.substring(2, 4), 16); // hexToG
        const b = parseInt(color.substring(4, 6), 16); // hexToB

        return ((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186;
    }

    // Custom Swatches
    renderSwatchValue(swatch) {
        const { secureBaseMediaUrl, product: { url } } = this.props;
        const { swatch_data: { value: color } } = swatch;
        const isLight = this.getIsColorLight(color);
        // const attribute_code = 'color';

        if (swatch.is_configured_by_color) {
            if (swatch.swatch_data.type === '2') {
                const style = {
                    '--option-is-selected': 0
                // stylelint-disable-next-line value-keyword-case
                };

                return (
                <Link
                  to={ `${url}?color=${swatch.value}` }
                  onClick={ (e) => this.handleOptionClick(e, swatch.value) }
                  onMouseEnter={ (e) => this.onMouseEnter(e, swatch.value) }
                  onMouseLeave={ (e) => this.onMouseLeave(e) }
                >
                    <img
                      block="ProductAttributeValue"
                      elem="Image"
                      src={ `${secureBaseMediaUrl}attribute/swatch${swatch.swatch_data.value}` }
                      alt={ swatch.label }

                    />
                    <data
                      block="ProductAttributeValue"
                      elem="Image-Overlay"
                      value={ swatch.label }
                      title={ swatch.label }
                      style={ style }
                    />
                </Link>
                );
            }

            const style = {
                '--option-background-color': color,
                '--option-border-color': isLight ? '#dddddd' : color,
                '--option-check-mark-background': isLight ? '#000' : '#fff',
                '--option-is-selected': 0
            // stylelint-disable-next-line value-keyword-case
            };

            return (
            <Link
              to={ `${url}?color=${swatch.value}` }
              onClick={ (e) => this.handleOptionClick(e, swatch.value) }
              onMouseEnter={ (e) => this.onMouseEnter(e, swatch.value) }
              onMouseLeave={ (e) => this.onMouseLeave(e) }
            >
            <data
              block="ProductAttributeValue"
              elem="Color"
              value={ swatch.swatch_data.value }
              title={ swatch.swatch_data.value }
              style={ style }
            />
            </Link>
            );
        }

        return null;
    }

    renderSwatchList() {
        const { plpColorSwatch, product, linkTo } = this.props;
        const updateLinkurlConfig = this.updateLinkWithColorForConfiguration(product, linkTo);

        if (plpColorSwatch) {
            const values_slice = 3;
            const swatchLength = plpColorSwatch[0].attribute_options.length > values_slice ? plpColorSwatch[0].attribute_options.length - values_slice : 0;
            return (
                <div block="ProductConfigurableAttributes" elem="SwatchList">
                { plpColorSwatch[0].attribute_options.slice(0, values_slice).map((swatch) => this.renderSwatchValue(swatch)) }
                { swatchLength > 0 && (
                            <Link
                              to={ updateLinkurlConfig }
                              block="More"
                              elem="Swatch"
                            >
                            { `+${swatchLength} More` }
                            </Link>
                ) }
                </div>
            );
        }

        return null;
    }

    onMouseEnterEventHandler({ attribute_code, attribute_value }) {
        const { updateConfigurableMouseEventForPlp } = this.props;

        updateConfigurableMouseEventForPlp({ attribute_code, attribute_value });
    }

    onMouseLeaveEventHandler() {
        const { updateConfigurableMouseEventForPlp } = this.props;

        updateConfigurableMouseEventForPlp(null);
    }

    onMouseEnter(e, attributeValue) {
        e.preventDefault();
        e.stopPropagation();
        const { plpColorSwatch } = this.props;
        const swatch = plpColorSwatch[0];

        const { attribute_code } = swatch;

        const attribute_value = swatch.attribute_options.find((attribute_Value) => attribute_Value.value === attributeValue);

        this.onMouseEnterEventHandler({ attribute_code, attribute_value });
    }

    handleOptionClick(e, attributeValue) {
        const { updateConfigurableMouseEventForPlp } = this.props;

        const { plpColorSwatch } = this.props;
        const swatch = plpColorSwatch[0];

        const { attribute_code } = swatch;

        const attribute_value = swatch.attribute_options.find((attribute_Value) => attribute_Value.value === attributeValue);

        updateConfigurableMouseEventForPlp({ attribute_code, attribute_value });
    }

    onMouseLeave(e) {
        e.preventDefault();
        e.stopPropagation();

        this.onMouseLeaveEventHandler();
    }

    renderConfigurableOptions() {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = {} },
            inStock,
            updateConfigurableVariantMouseEvent
        } = this.props;

        if (type !== PRODUCT_TYPE.configurable) {
            return null;
        }

        return (
            <div block="ProductActions" elem="AttributesWrapper">
                <ProductConfigurableAttributes
                    // eslint-disable-next-line no-magic-numbers
                  numberOfPlaceholders={ [2, 4] }
                  mix={ { block: this.className, elem: 'Attributes' } }
                  parameters={ parameters }
                  variants={ variants }
                  updateConfigurableVariant={ setActiveProduct }
                  updateConfigurableVariantMouseEvent={ updateConfigurableVariantMouseEvent }
                  configurable_options={ this.getConfigurableAttributes() }
                  isContentExpanded
                  isExpandable={ false }
                  inStock={ inStock }
                />
            </div>
        );
    }

    render() {
        const {
            children, mix, isLoading, layout
        } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <div block="ProductCard" mods={ { layout } } mix={ mix }>
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </div>
            );
        }

        return (
            <div block="ProductCard" mods={ { layout } } mix={ mix }>
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </div>
        );
    }
}

export default ProductCardComponent;
