import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
// import transformToNameValuePair from 'Util/Form/Transform';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';

import GiftCardQuery from '../../query/GiftCard.query';
import ApplyGiftCart from './ApplyGiftCart.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
/** @namespace GiftCard/Component/ApplyGiftCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartId: state.CartReducer.cartTotals.id,
    currenyCode: state.ConfigReducer.default_display_currency_code
});

/** @namespace GiftCard/Component/ApplyGiftCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateInitialCartData: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    )
});

/** @namespace GiftCard/Component/ApplyGiftCart/Container */
export class ApplyGiftCartContainer extends PureComponent {
    static propTypes = {
        cartId: PropTypes.string,
        currenyCode: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        updateInitialCartData: PropTypes.func.isRequired,
        getPaymentMethods: PropTypes.func.isRequired
    };

    static defaultProps = {
        cartId: ''
    };

    containerFunctions = {
        handleApplyGiftCardSubmit: this.handleApplyGiftCardSubmit.bind(this),
        handleAppliedGiftCardStatus: this.handleAppliedGiftCardStatus.bind(this),
        handleGiftCardStatus: this.handleGiftCardStatus.bind(this),
        handleRemoveAppliedGiftCard: this.handleRemoveAppliedGiftCard.bind(this)
    };

    state = {
        isLoading: false,
        isApplied: false,
        isStatus: false,
        appliedGiftCard: '',
        giftStatus: ''
    };

    containerProps() {
        // isDisabled: this._getIsDisabled()
        const {
            currenyCode
        } = this.props;
        const {
            isLoading,
            isApplied,
            appliedGiftCard,
            isStatus,
            giftStatus
        } = this.state;

        return {
            isLoading,
            currenyCode,
            isApplied,
            appliedGiftCard,
            giftStatus,
            isStatus
        };
    }

    async handleApplyGiftCardSubmit(giftCardCode) {
        const {
            cartId, showNotification, updateInitialCartData, getPaymentMethods
        } = this.props;

        const input = {
            cart_id: cartId,
            gift_card_code: giftCardCode
        };

        this.setState({
            isLoading: true
        });

        try {
            const mutation = GiftCardQuery.applyGiftQuery(input);
            const response = await fetchMutation(mutation);
            this.setState({
                isApplied: true,
                isLoading: false,
                appliedGiftCard: response.applyMwGiftCardToCart.cart.applied_mw_gift_cards[0]
            });
            updateInitialCartData();
            getPaymentMethods();
        } catch (error) {
            this.setState({
                isLoading: false
            });
            showNotification('error', getErrorMessage(error));
        }

        return null;
    }

    async handleAppliedGiftCardStatus() {
        const { cartId } = this.props;
        const cart_id = !isSignedIn() ? cartId : '';

        this.setState({
            isLoading: true
        });

        try {
            const mutation = GiftCardQuery.getCartGiftCardInfoQuery({ cart_id });
            const response = await fetchQuery(mutation);
            if (response.CartGiftCardInfo === null) {
                this.setState({
                    isLoading: false,
                    isApplied: false,
                    isStatus: false,
                    appliedGiftCard: '',
                    giftStatus: ''
                });
            } else {
                this.setState({
                    isApplied: true,
                    isLoading: false,
                    appliedGiftCard: response.CartGiftCardInfo
                });
            }
        } catch (error) {
            this.setState({
                isLoading: false,
                isApplied: false
            });
        }

        return null;
    }

    async handleRemoveAppliedGiftCard(giftCardCode) {
        const {
            cartId, showNotification, updateInitialCartData, getPaymentMethods
        } = this.props;

        const input = {
            cart_id: cartId,
            gift_card_code: giftCardCode
        };

        this.setState({
            isLoading: true
        });

        try {
            const mutation = GiftCardQuery.removeAppliedGiftCard(input);
            await fetchMutation(mutation);
            this.setState({
                isLoading: false,
                isApplied: false,
                isStatus: false,
                appliedGiftCard: '',
                giftStatus: ''
            });
            showNotification('success', 'Appied Gift Card Removed.');
            updateInitialCartData();
            getPaymentMethods();
        } catch (error) {
            this.setState({
                isLoading: false
            });
            showNotification('error', 'Gift Card does not exist');
        }

        return null;
    }

    async handleGiftCardStatus(giftCardCode) {
        const { showNotification } = this.props;
        const { isStatus } = this.state;
        const code = giftCardCode;

        this.setState({
            isLoading: true,
            isStatus
        });

        try {
            const mutation = GiftCardQuery.giftCardStatus(code);

            const response = await fetchQuery(mutation);
            this.setState({
                isLoading: false,
                isStatus: true,
                giftStatus: response
            });
        } catch (error) {
            this.setState({
                isLoading: false
            });
            showNotification('error', 'Gift Card Status does not exist');
        }

        return null;
    }

    render() {
        return (
            <ApplyGiftCart
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyGiftCartContainer);
