export const LICENSE_CODE = 'in~58adcc57';

export const CATEGORY_VIEW = 'Product Category Viewed';
export const SPORTS_CATEGORY = 'Sports Category Viewed';
export const COLLECTION_CATEGORY = 'Collection Category Viewed';
export const FEATURED_CATEGORY = 'Featured Category Viewed';
export const SOCIAL_MEDIA = 'Social Media Page Viewed';
export const ORDER_TRACKED = 'Order Tracked';
export const SHIPPING_POLICY = 'Shipping Policy Viewed';
export const RETURN_POLICY = 'Return Policy Viewed';
export const GIFT_CARDS_PAGE = 'Gift Cards Page Viewed';
export const GIFT_CARD = 'Gift Card Viewed';
export const ADD_TO_CART_GIFT_CARD = 'Add to Bag- Gift Card';
export const ADD_TO_WISHLIST_GIFT_CARD = 'Add to Wishlist- Gift Card';
export const PRODUCT_SEARCHED = 'Product Searched';
export const ADD_TO_CART = 'Add to Cart';
export const SIGNED_UP_FOR_NEWSLETTER = 'Newsletter Subscribed';
export const STORE_SEARCHED = 'Store Searched';
export const STORE_VIEWED = 'Store Viewed';
export const CONTACT_US = 'Contact Us Submitted';
export const CART_UPDATED = 'Cart Updated';
export const WISHLIST_UPDATED = 'Wishlist Updated';
export const ORDER_CANCELLED = 'Order Cancelled';
export const PAYMENT_STARTED = 'Payment Started';
export const CHECKOUT_STARTED = 'Checkout Initiated';
export const CHECKOUT_COMPLETED = 'Checkout Completed';
export const CONTINUE_PAYMENT = 'Continue To Payment';
export const PRODUCT_VIEWED = 'Product Viewed';
export const ADD_TO_WISHLIST = 'Add to Wishlist';
export const SAVE_FOR_LATER = 'Save For Later';
export const SORT_BY = 'Sort By';
export const USER_LOGGED_IN = 'User Logged In';
export const FILTER_APPLIED = 'Filter Applied';
export const USER_SIGNED_UP = 'User Signed Up';
export const PAGE_VIEWED = 'Page Viewed';
export const GUEST_USER_CHECKOUT = 'Guest User Checkout';
