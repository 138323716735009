/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-use-before-define */
import { PAGE_VIEWED, STORE_SEARCHED, STORE_VIEWED } from '../config';
import { eventTrack } from '../helper/WebEngage/Event';

export const renderContent = (args, callback, instance) => {
    const { stores, isMobile } = instance.props;
    const { checkLength } = instance.state;

    if (isMobile) {
        return (
            <div block="StoreLocatorContent" elem="Data">
            <h4 block="StoreLocatorContent" elem="Header">
                { stores.length }
                { ' ' }
                stores near you
            </h4>
            <ul block="StoreLocatorContent" elem="Content">
            { stores.slice(0, checkLength).map((store) => (

                <li>
                <h4>
                    { store.store_name }
                </h4>
                <p>
                    { store.address }
                </p>
                <a onClick={ () => handleWebengageEvent(store) } href={ `https://www.google.com/maps?q=${store.latitude},${store.longitude}` } target="_blank" rel="noreferrer">Get Directions</a>
                </li>
            )) }
            </ul>
            { stores.length > 1 && (
                <button
                  block="ShowMore"
                  mods={ { isActive: instance.state.checkLength > 1 } }
                  onClick={ instance.renderMapList }
                >
                                ...show more

                </button>
            ) }
            </div>

        );
    }

    return (
            <div block="StoreLocatorContent" elem="Data">
            <h4 block="StoreLocatorContent" elem="Header">
                { stores.length }
                { ' ' }
                stores near you
            </h4>
            <ul block="StoreLocatorContent" elem="Content">
            { stores.map((store) => (

                <li>
                <h4>
                    { store.store_name }
                </h4>
                <p>
                    { store.address }
                </p>
                <a onClick={ () => handleWebengageEvent(store) } href={ `https://www.google.com/maps?q=${store.latitude},${store.longitude}` } target="_blank" rel="noreferrer">Get Directions</a>
                </li>
            )) }
            </ul>
            </div>

    );
};

const handleWebengageEvent = (store) => {
    eventTrack(STORE_VIEWED, {
        'Store Name': store.store_name,
        Address: store.address
    });
};

const requestStores = (args, callback) => {
    callback(...args);

    const [search] = args;

    if (search) {
        eventTrack(STORE_SEARCHED, {
            'Searched Keyword': search
        });
    }
};

const componentDidMount = (args, callback, instance) => {
    callback(...args);

    const { location: { pathname } } = instance.props;

    eventTrack(PAGE_VIEWED, {
        'Page Name': 'Store Locator',
        'Page Path': pathname
    });
};

export default {
    'StoreLocator/Route/StoreLocator/Component': {
        'member-function': {
            renderContent
        }
    },
    'StoreLocator/Route/StoreLocator/Container': {
        'member-function': {
            requestStores, componentDidMount
        }
    }
};
