/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import TimerButton from 'Component/TimerButton';
import { MyAccountSignIn as SourceMyAccountSignIn } from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import {
    RESEND_OTP, SIGNIN, SIGNIN_SEND_OTP, SIGNIN_VERIFY_OTP
} from './MyAccountSignIn.config';

import './MyAccountSignIn.override.style';
/** @namespace myApp/Component/MyAccountSignIn/Component */
export class MyAccountSignInComponent extends SourceMyAccountSignIn {
    static propTypes = {
        currentRenderForm: PropTypes.func.isRequired,
        handleRenderForm: PropTypes.func.isRequired,
        prepone: PropTypes.string,
        handleResendOTP: PropTypes.func.isRequired
    };

    static defaultProps = {
        prepone: '',
        isVisible: ''
    };

    state = {
        type: 'password',
        score: 'null'
    };

    showHidePassword = this.showHidePassword.bind(this);

    passwordStrength = this.passwordStrength.bind(this);

    renderMap = {
        [SIGNIN]: this.renderSignInForm.bind(this),
        [SIGNIN_SEND_OTP]: this.renderSignInSendOTPForm.bind(this),
        [SIGNIN_VERIFY_OTP]: this.renderSignInVerifyOTPForm.bind(this),
        [RESEND_OTP]: this.renderResend.bind(this)
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    showHidePassword(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'text' ? 'password' : 'text'
        });
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    passwordStrength(e) {
        if (e.target.value === '') {
            this.setState({
                score: 'null'
            });
        } else {
            const pw = zxcvbn(e.target.value);
            this.setState({
                score: pw.score
            });
        }
    }

    renderForm() {
        const { currentRenderForm } = this.props;

        return this.renderMap[currentRenderForm]();
    }

    renderResend() {
        const {
            onSignInAttempt,
            handleSignInVerifyOTPSuccess,
            onFormError,
            isCheckout,
            formFields,
            handleBackToPrevious
        } = this.props;

        return (
            <Form
              key="sign-in-verify"
              onSubmit={ onSignInAttempt }
              onSubmitSuccess={ handleSignInVerifyOTPSuccess }
              onError={ onFormError }
            >
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Mobile Number') }
                  attr={ {
                      id: 'mobileNumber',
                      name: 'mobileNumber',
                      autocomplete: isCheckout ? 'off' : 'mobileNumber',
                      placeholder: __('Mobile Number'),
                      value: formFields.mobileNumber,
                      prepone: '+91'
                  } }
                  validation={ ['notEmpty', 'mobileNumber'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.mobile
                  } }
                />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('OTP') }
                  attr={ {
                      id: 'otp',
                      name: 'otp',
                      value: formFields.otp,
                      autocomplete: isCheckout ? 'off' : 'otp'
                  } }
                  validation={ ['notEmpty', 'otp'] }
                  validationRule={ {
                      isRequired: true
                  } }
                />
                <div block="MyAccountOverlay" elem="SignInWithOtpButton">
                    <button block="Button" type="button" onClick={ handleBackToPrevious }>
                        { __('Retry') }
                    </button>
                </div>
                <div block="MyAccountOverlay" elem="SignInWithOtpButton">
                    <button block="Button" type="submit">
                        { __('submit') }
                    </button>
                </div>
            </Form>
        );
    }

    renderSignInVerifyOTPForm() {
        const {
            // onSignInAttempt,
            handleSignInVerifyOTPSuccess,
            onFormError,
            isCheckout,
            formFields,
            handleResendOTP
        } = this.props;

        return (
            <Form
              key="sign-in-verify"
                //   onSubmit={ onSignInAttempt }
              onSubmit={ handleSignInVerifyOTPSuccess }
              onSubmitSuccess={ handleSignInVerifyOTPSuccess }
              onError={ onFormError }
            >
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Mobile Number *') }
                  attr={ {
                      id: 'mobileNumber',
                      name: 'mobileNumber',
                      value: formFields.mobileNumber,
                      prepone: '+91',
                      maxlength: '10',
                      autocomplete: isCheckout ? 'off' : 'number'
                  } }
                  validation={ ['notEmpty', 'mobileNumber'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.text
                  } }
                />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('OTP *') }
                  attr={ {
                      id: 'otp',
                      name: 'otp',
                      value: formFields.otp,
                      autocomplete: isCheckout ? 'off' : 'otp'
                  } }
                  validation={ ['notEmpty', 'otp'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.text
                  } }
                />
                <div block="MyAccountOverlay" elem="SignInWithOtpButton">
                    <button block="Button">{ __('Verify') }</button>
                </div>
                <div block="ResendOtp" elem="Wrapper" mods={ { isLoginOtp: true } }>
                <span>
                { __('Not received your code?') }
                </span>
          <TimerButton onClick={ handleResendOTP }>{ __('Resend OTP') }</TimerButton>
                </div>
            </Form>
        );
    }

    renderSignInSendOTPForm() {
        const {
            handleSignInSendOTPSuccess,
            // onSignInAttempt,
            // onSignInSuccess,
            onFormError,
            isCheckout,
            formFields
        } = this.props;

        return (
            <Form
              key="sign-in-otp"
                //   onSubmit={ onSignInAttempt }
              onSubmit={ handleSignInSendOTPSuccess }
              onSubmitSuccess={ handleSignInSendOTPSuccess }
                //   onSubmitError={ onFormError }
              onError={ onFormError }
            >
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Mobile Number') }
                  attr={ {
                      id: 'mobileNumber',
                      name: 'mobileNumber',
                      maxlength: '10',
                      prepone: '+91',
                      value: formFields.mobileNumber,
                      //   autocomplete: isCheckout ? 'off' : 'mobileNumber'
                      autocomplete: isCheckout ? 'off' : 'number'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.text
                  } }
                  addRequiredTag
                />
                <div block="Terms" elem="condition">
                    { __('By logging in, you agree to the') }
                    &nbsp;
                    <Link
                      to="/customer-service/terms-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      block="Terms"
                      elem="Condition"
                    >
                        { __('Terms & Conditions') }
                        &nbsp;
                    </Link>
                    and &nbsp;
                    <Link
                      to="/customer-service/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      block="Privacy"
                      elem="Policy"
                    >
                        { __('Privacy Policy') }
                    </Link>
                </div>
                <div block="MyAccountOverlay" elem="SignInWithOtpButton">
                    <button block="Button">{ __('Login With OTP') }</button>
                </div>
            </Form>
        );
    }

    renderSignInForm() {
        const {
            onSignInSuccess, onFormError, handleForgotPassword, emailValue, isCheckout
        } = this.props;

        const { type } = this.state;

        return (
            <Form key="sign-in" onSubmit={ onSignInSuccess } onError={ onFormError }>
                <Field
                  label={ __('Email Address') }
                  type={ FIELD_TYPE.email }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      placeholder: __('Your email address'),
                      defaultValue: emailValue,
                      autocomplete: isCheckout ? 'off' : 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email,
                      customErrorMessages: {
                          onRequirementFail: __('Please enter email address')
                      }
                  } }
                  addRequiredTag
                />
                <div block="Password" elem="Wrapper">
                    <Field
                      label={ __('Password') }
                        //   type={ FIELD_TYPE.password }
                      type={ this.state.type }
                      attr={ {
                          id: 'password',
                          name: 'password',
                          placeholder: __('Enter your password'),
                          autocomplete: 'current-password'
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true,
                          customErrorMessages: {
                              onRequirementFail: __('Please enter password')
                          }
                      } }
                        // prepone="+91"
                        // isVisible="Show"
                      addRequiredTag
                    />
                    <span
                      block="showHide"
                      elem="btn"
                        // eslint-disable-next-line react/jsx-props-no-multi-spaces
                      onClick={ this.showHidePassword }
                    >
                        { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional  */ }
                        { type === 'text' ? 'Hide' : 'Show' }
                    </span>
                    <span className="password__strength" data-score={ this.state.score } />
                </div>
                <div block="Combine" elem="Auth">
                    { /* <div block="Field">
                        <label htmlFor="is_remember" block="Field" elem="CheckboxLabel">
                            <input type="checkbox" id="is_remember" />
                            <div block="input" elem="control" />
                            Remember me
                        </label>
                    </div> */ }
                    <button
                      type="button"
                      block="Button"
                      mods={ { likeLink: true } }
                      mix={ { block: 'MyAccountOverlay', elem: 'ForgotPassword' } }
                      onClick={ handleForgotPassword }
                    >
                        { __('Forgot password?') }
                    </button>
                </div>
                <div block="Terms" elem="condition">
                    { __('By logging in, you agree to the') }
                    &nbsp;
                    <Link
                      to="/customer-service/terms-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      block="Terms"
                      elem="Condition"
                    >
                        { __('Terms & Conditions') }
                        &nbsp;
                    </Link>
                    and &nbsp;
                    <Link
                      to="/customer-service/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      block="Privacy"
                      elem="Policy"
                    >
                        { __('Privacy Policy') }
                    </Link>
                </div>

                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">{ __('Sign in') }</button>
                </div>
            </Form>
        );
    }

    renderAdditionalField() {
        const {
            isCheckout, handleCreateAccount, state, currentRenderForm
        } = this.props;

        if (isCheckout) {
            return null;
            // eslint-disable-next-line max-lines
        }

        return (
            <article
              block="MyAccountOverlay"
              elem="Additional"
              mods={ { state, isActive: currentRenderForm === SIGNIN_VERIFY_OTP } }
            >
                <section>
                    <h4 id="forgot-password-label">{ __('New to Under Armour?') }</h4>
                    <button block="Button" mods={ { likeLink: true } } onClick={ handleCreateAccount }>
                        { __('Register') }
                    </button>
                </section>
            </article>
        );
    }

    render() {
        const { handleRenderForm, currentRenderForm, isLoading } = this.props;

        return (
            <>
                <Loader isLoading={ isLoading } />
                <div block="MyAccountSignIn">
                    <div
                      block="MyAccountSignIn"
                      elem="Email"
                      mods={ { isActive: currentRenderForm === SIGNIN } }
                      onClick={ () => handleRenderForm(SIGNIN) }
                    >
                        Log In with Password
                    </div>
                    <div
                      block="MyAccountSignIn"
                      elem="Otp"
                        // eslint-disable-next-line max-len
                      mods={ {
                          isActive:
                                currentRenderForm === SIGNIN_SEND_OTP
                                || currentRenderForm === SIGNIN_VERIFY_OTP
                                || currentRenderForm === RESEND_OTP
                      } }
                      onClick={ () => handleRenderForm(SIGNIN_SEND_OTP) }
                    >
                        Log In with OTP
                    </div>
                </div>
                <div className="SignInForm">
                    { this.renderForm() }
                    { this.renderAdditionalField() }
                </div>
            </>
        );
    }
}

export default MyAccountSignInComponent;
