import { Field } from 'Util/Query';

function getSalesOrderItem() {
    return new Field('items')
        .addFieldList([
            'title',
            'sku',
            'price',
            'qty_ordered',
            'product_image',
            'size',
            'color',
            'gender'
        ]);
}

function getSalesOrderQuery(args, callback, instance) {
    const [orderId] = args;
    return new Field('salesOrder')
        .addArgument('id', 'String!', orderId)
        .addFieldList([
            'increment_id',
            'customer_name',
            'subtotalincltax',
            'shippingamount',
            'grand_total',
            'is_guest_customer',
            'created_at',
            'shipping_method',
            'coupncode',
            'coupncodevalue',
            'giftcard_code',
            'gifrcardamount',
            'payment_method',
            instance.getShippingAddressQuery(),
            instance.getBillingAddressQuery(),
            instance.getSalesOrderItem()
        ]);
}

export default {
    'myApp/Query/ThankyouPageOrder/Query': {
        'member-function': {
            getSalesOrderItem, getSalesOrderQuery
        }
    }
};
