import { Field } from 'Util/Query';

/** @namespace Rma/Query/CustomerRma/Query */
export class CustomerRmaQuery {
    getRmaList() {
        return new Field('customerRmas')
            .addField(this._getRmaListItems());
    }

    getRmaById(rmaId) {
        return new Field('customerRma')
            .addArgument('rmaId', 'String!', rmaId)
            .addField(this._getRma());
    }

    getRmaDictionary(orderId) {
        return new Field('rmaDictionary')
            .addArgument('orderId', 'String!', orderId)
            .addFieldList([
                this._getReturnReasonsFields(),
                this._getItemConditionsFields(),
                this._getItemResolutionsFields()
            ]);
    }

    _getRmaListItems() {
        return new Field('items')
            .addFieldList([
                'rma_id',
                'id',
                'increment_id',
                'order_increment_id',
                'status',
                'status_label',
                'created_at'
            ]);
    }

    _getRma() {
        return new Field('rma')
            .addFieldList([
                'rma_id',
                'increment_id',
                'order_increment_id',
                'status',
                'status_label',
                'created_at',
                this._getRmaShippingAddress(),
                this._getRmaOrders()
            ]);
    }

    _getRmaShippingAddress() {
        return new Field('shipping_address')
            .addFieldList([
                'firstname',
                'lastname',
                'country_id',
                'city',
                'street',
                'region',
                'telephone',
                'postcode'
            ]);
    }

    _getRmaOrders() {
        return new Field('orders')
            .addFieldList([
                'order_number',
                'type',
                this._getRmaOrderItems()
            ]);
    }

    _getRmaOrderItems() {
        return new Field('items')
            .addFieldList([
                'image_url',
                'item_name',
                'item_sku',
                'qty_requested',
                'condition_id',
                'reason_id',
                'resolution_id',
                new Field('condition_name').setAlias('condition_label'),
                new Field('reason_name').setAlias('reason_label'),
                new Field('resolution_name').setAlias('resolution_label')
                // 'customer_name',
                // 'bank_name',
                // 'account_number',
                // 'ifsc_code'
            ]);
    }

    _getReturnReasonsFields() {
        return new Field('reasons')
            .addField(new Field('name').setAlias('label'))
            .addField('value')
            .addField('is_active');
    }

    _getItemConditionsFields() {
        return new Field('conditions')
            .addField(new Field('name').setAlias('label'))
            .addField('value')
            .addField('is_active');
    }

    _getItemResolutionsFields() {
        return new Field('resolutions')
            .addField(new Field('name').setAlias('label'))
            .addField('value')
            .addField('is_active');
    }

    createRmaMutation(options) {
        return new Field('createRma')
            .addArgument('input', 'CreateRmaInput!', options)
            .addField(new Field('rma').addField('increment_id'));
    }
}

export default new (CustomerRmaQuery)();
