/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unescaped-entities */

import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import {
    MyAccountOrderItemsTableRow as SourceMyAccountOrderItemsTableRow
} from 'SourceComponent/MyAccountOrderItemsTableRow/MyAccountOrderItemsTableRow.component';
import { formatPrice } from 'Util/Price';

import './MyAccountOrderItemsTableRow.override.style';

/** @namespace myApp/Component/MyAccountOrderItemsTableRow/Component */
export class MyAccountOrderItemsTableRowComponent extends SourceMyAccountOrderItemsTableRow {
    renderPrductSku() {
        const {
            product: {
                product_sku
            }
        } = this.props;

        return (
            <dl>
                <dt block="MyAccountOrderItemsTableRow" elem="OptionLabel">Sku:</dt>
                <dl block="MyAccountOrderItemsTableRow" elem="OptionValue">{ product_sku }</dl>
            </dl>
        );
    }

    renderNameAndOptions() {
        const { product: { product_name } } = this.props;

        return (
            <>
                <span
                  block="MyAccountOrderItemsTableRow"
                  elem="Name"
                >
                    { product_name }
                </span>
                { this.renderPrductSku() }
                { this.renderSelectedAndEnteredOptions() }
            </>
        );
    }

    renderSelectItemQty() {
        const {

            product: { quantity_ordered }
        } = this.props;

        if (!quantity_ordered) {
            return null;
        }

        return (
            <dl>
                <dt block="MyAccountOrderItemsTableRow" elem="OptionLabel">Qty:</dt>
                <dt block="MyAccountOrderItemsTableRow" elem="OptionValue">{ quantity_ordered }</dt>
            </dl>
        );
    }

    renderSelectedAndEnteredOptions() {
        const {
            selectedOptions,
            enteredOptions
        } = this.props;
        const { renderOption } = this.renderMap;
        if (!selectedOptions.length && !enteredOptions.length) {
            return null;
        }

        return (
            <>
                { selectedOptions.map(renderOption) }
                { enteredOptions.map(renderOption) }
            </>
        );
    }

    renderOption(option) {
        const {
            label,
            items,
            value
        } = option || [];

        if (items) {
            return null;
        }

        return (
            <dl key={ `${ label }-${ value }` }>
                <dt
                  block="MyAccountOrderItemsTableRow"
                  elem="OptionLabel"
                >
                   { label }
                    :
                </dt>
                { this.renderOptionContent(option) }
            </dl>
        );
    }

    renderOrderShippingAddress() {
        const { order: { shipping_address } } = this.props;
        if (!shipping_address) {
            return null;
        }

        return (
            <div block="MyAccountOrderItemsTableRow" elem="ShippingAddress">
                <h4>Shipped To: </h4>
                <p>
                    { `${shipping_address.firstname}` }
                </p>
                <p>
                    { `${shipping_address.city}` }
                </p>
                <p>
                    { `${shipping_address.street}` }
                </p>
                <p>
                    { `${shipping_address.region}` }
                </p>
                <p>
                    { __('Pincode:') }
                    { ' ' }
                    { shipping_address.postcode }
                </p>
                { /* <p>
                    { __('Mobile:') }
                    { ` ${shipping_address.telephone}` }
                </p> */ }
            </div>

        );
    }

    renderPrice(value, currency) {
        return (
            <p block="MyAccountOrderItemsTableRow" elem="Price">
                <strong>{ formatPrice(value, currency) }</strong>
            </p>
        );
    }

    renderButton() {
        const { product: { product_url, config_sku, product_type }, addProductToCart } = this.props;

        if (product_type === 'mageworx_giftcards') {
            return (
                <Link to={ `${product_url}` } block="MyAccountOrderItemsTableRow" elem="BuyAgain">
            Buy Again
                </Link>
            );
        }

        return (
            <button
              block="MyAccountOrderItemsTableRow"
              elem="BuyAgain"
              onClick={ addProductToCart }
            >
                Buy Again
            </button>
        );
    }

    renderActionWrapper() {
        const {
            order: { id, status },
            product: {
                order_item_id, quantity_ordered, quantity_canceled
            },
            cancelOrderItemPopup
        } = this.props;

        return (
            <div block="MyAccountOrderItemsTableRow" elem="Actions">
                { this.renderButton() }
                { /* { !quantity_canceled && status === 'In Process' || status === 'Processing'
                    ? (
                     <button
                       block="MyAccountOrderItemsTableRow"
                        // eslint-disable-next-line react/jsx-no-bind
                       onClick={ () => cancelOrderItemPopup({ id, order_item_id, quantity_ordered }) }
                       elem="Cancel"
                     >
                        Cancel
                     </button>
                    ) : null } */ }

                    { /* { quantity_canceled ? <span block="textred cancelItemMsg">This item has been Cancelled.</span> : null } */ }
            </div>
        );
    }

    renderProductImage() {
        const { product: { product_image } } = this.props;

        return (
            <Image src={ product_image } alt="image" />
        );
    }

    renderDesktopTableRow() {
        const {
            enteredOptions = []
        } = this.props;
        const isWithEnteredItems = !!enteredOptions[0]?.items;

        return (
            <>
                <li
                  block="MyAccountOrderItemsTableRow"
                  elem="RowWrapper"
                  mods={ { isWithEnteredItems } }
                >
                    <div block="MyAccountOrderItemsTableRow" elem="Image">
                        { this.renderProductImage() }
                    </div>
                    <div block="MyAccountOrderItemsTableRow" elem="ProductDesc">
                        { this.renderNameAndOptions() }
                        { this.renderSelectItemQty() }
                        { this.renderOrderShippingAddress() }
                        { this.renderRowSubtotal() }
                        { this.renderActionWrapper() }
                    </div>
                </li>
                { this.renderEnteredOptionsAsRow() }
                { /* { this.renderCancelOrderItemPopup() } */ }
            </>
        );
    }

    render() {
        const { isLoading } = this.props;
        return (
            <>
            <Loader isLoading={ isLoading } />
            { this.renderDesktopTableRow() }

            </>
        );
    }
}

export default MyAccountOrderItemsTableRowComponent;
