/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Link from 'Component/Link';
import Loader from 'Component/Loader';

import MyAccountNewReturnAddressTable from '../MyAccountNewReturnAddressTable';
import MyAccountNewReturnCustomerTable from '../MyAccountNewReturnCustomerTable';
import MyAccountNewReturnItemSelect from '../MyAccountNewReturnItemSelect';

import './MyAccountNewReturn.style';

/** @namespace Rma/Component/MyAccountNewReturn/Component */
export class MyAccountNewReturnComponent extends PureComponent {
    static propTypes = {
        shippingAddress: PropTypes.objectOf(),
        reasonData: PropTypes.objectOf().isRequired,
        baseOrderInfo: PropTypes.objectOf().isRequired,
        onNewRequestSubmit: PropTypes.func.isRequired,
        orderId: PropTypes.string.isRequired,
        history: PropTypes.objectOf().isRequired,
        isLoading: PropTypes.bool.isRequired,
        renderPageTitle: PropTypes.func.isRequired,
        items: PropTypes.arrayOf().isRequired,
        createdAt: PropTypes.string.isRequired,
        updateReturnAddress: PropTypes.func.isRequired
    };

    static defaultProps = {
        shippingAddress: {}
    };

    state = {
        selectedItems: {},
        hasItemsError: false,
        messageText: '',
        isChecked: false
    };

    handleSelectedItemsChange = (selectedItems) => {
        this.setState({ selectedItems });
    };

    handleRequestSubmitPress = async () => {
        const { orderId, shippingAddress, onNewRequestSubmit } = this.props;
        const { selectedItems, messageText } = this.state;

        if (!Object.keys(selectedItems).length) {
            return;
        }

        const allSelectedNonSelectedItem = Object.values(selectedItems).reduce((acc, item) => {
            const { isCheckedBox, ...rest } = item;

            acc[item.order_item_id] = rest;

            return acc;
        }, { });

        const selectedItem = Object.values(selectedItems).reduce((acc, item) => {
            const { isCheckedBox, ...rest } = item;

            if (isCheckedBox) {
                acc[item.order_item_id] = rest;
            }

            return acc;
        }, { });

        if (selectedItem) {
            const isAllFilled = !Object.values(selectedItem).find((selectedItem) => (
                Object.values(selectedItem).find((item) => !item) !== undefined
            ));

            if (!isAllFilled) {
                this.setState({ hasItemsError: true });
                return;
            }
        }

        onNewRequestSubmit({
            items: allSelectedNonSelectedItem,
            order_id: orderId,
            message: messageText,
            rmaAddress: shippingAddress
        });
    };

    handleBackPress = () => {
        const { history } = this.props;

        history.goBack();
    };

    handleTextAreaChange = (value) => {
        this.setState({ messageText: value });
    };

    isButtonEnabled() {
        const { isChecked } = this.state;
        // const isSubmitDisabled = !Object.keys(selectedItems).length;
        const isSubmitDisabled = !isChecked;

        return isSubmitDisabled;
    }

    renderActions() {
        return (
            <div
              block="MyAccountNewReturn"
              elem="Actions"
            >
                <button
                  block="Button"
                  onClick={ this.handleRequestSubmitPress }
                  disabled={ this.isButtonEnabled() }
                >
                    { __('Submit request') }
                </button>
                <button
                  block="Button"
                  mods={ { isHollow: true } }
                  onClick={ this.handleBackPress }
                >
                    { __('Cancel') }
                </button>
            </div>
        );
    }

    renderLoader() {
        const { isLoading } = this.props;
        return <Loader isLoading={ isLoading } />;
    }

    renderMessageTextArea() {
        const { messageText } = this.state;

        return (
            <Field
              type="textarea"
              placeholder="Please describe your issue in details."
              id="message"
              name="message"
              mix={ {
                  block: 'MyAccountNewReturn',
                  elem: 'MessageTextArea'
              } }
              value={ messageText }
              onChange={ this.handleTextAreaChange }
            />
        );
    }

    renderMessageSection() {
        return (
            <div>
                <h4
                  block="MyAccountNewReturn"
                  elem="MessageTitle"
                >
                    { __('Message:') }
                </h4>
                { this.renderMessageTextArea() }
            </div>
        );
    }

    renderPolicy() {
        return (
            <div block="Policy" elem="Wrapper">
                <p>
                <strong>Returns and Exchange Policy</strong>
                    You have read our
                    <Link
                      to="/customer-service/privacy-policy"
                      target="_blank"
                    >
                    { __(' return policy') }
                    </Link>
                </p>
            </div>
        );
    }

    handleAgreeCheckbox=(e) => {
        const item = e.target.checked;
        this.setState({ isChecked: item });
    };

    renderAgree() {
        const { isChecked } = this.state;
        return (
            <Field
              id="agree"
              label="I Agree"
              name="Agree"
              type={ FIELD_TYPE.checkbox }
              mix={ {
                  block: 'Agree'
              } }
              attr={ {
                  checked: isChecked
              } }
              events={ {
                  onChange: this.handleAgreeCheckbox
              } }
            />
        );
    }

    render() {
        const {
            reasonData,
            items,
            createdAt,
            shippingAddress,
            updateReturnAddress,
            baseOrderInfo: { increment_id: orderIncrementId = '' }
        } = this.props;

        const { hasItemsError } = this.state;

        return (
            <ContentWrapper
              wrapperMix={ { block: 'MyAccountNewReturn', elem: 'Wrapper' } }
            >
            <div block="MyAccountNewReturn">
                { this.renderLoader() }
                { /* { renderPageTitle(orderIncrementId) } */ }
                <h3 block="MyAccountNewReturn" elem="Heading">{ `${'Order'}${'#'}${ orderIncrementId }` }</h3>
                <div
                  block="MyAccountNewReturn"
                  elem="CustomerAndAddressBlocks"
                >
                    <MyAccountNewReturnCustomerTable />
                    <MyAccountNewReturnAddressTable
                      address={ shippingAddress }
                      updateReturnAddress={ updateReturnAddress }
                      isEditShow={ false }
                    />
                </div>
                <MyAccountNewReturnItemSelect
                  onItemChange={ this.handleSelectedItemsChange }
                  reasonData={ reasonData }
                  items={ items }
                  hasError={ hasItemsError }
                  createdAt={ createdAt }
                />
                { this.renderPolicy() }
                { this.renderAgree() }
                { this.renderActions() }
            </div>
            </ContentWrapper>
        );
    }
}

export default MyAccountNewReturnComponent;
