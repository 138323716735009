/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';

import './Notification.override.style';

/** @namespace myApp/Component/Notification/Component */
export class NotificationComponent extends SourceNotification {
    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };
        const title = msgType === 'error' ? 'Oops Something went wrong' : null;

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                <button
                  block="Notification"
                  elem="Button"
                  onClick={ (e) => this.hideNotification(e.nativeEvent.stopImmediatePropagation()) }
                >
                    Close
                </button>
                { title && (<h3 block="Notification" elem="Header">{ title }</h3>) }
                <p block="Notification" elem="Text">{ msgText }</p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default NotificationComponent;
