import PRODUCT_TYPE from 'Component/Product/Product.config';
import { handleGAEventsTracker } from 'Util/Helper';
import getStore from 'Util/Store';

import { ADD_TO_CART, CART_UPDATED } from '../config';
import { getCartProductsDetailsInArray } from '../helper/Cart';
import { eventTrack } from '../helper/WebEngage/Event';

/** @namespace Ambab/Webengage/Plugin/WishlistItem/Container/addItemToCart */
export const addItemToCart = (args, callback, instance) => {
    callback(...args).then(() => {
        const {
            product: {
                wishlist: { sku, options, quantity }, gender,
                price_range: {
                    minimum_price: {
                        final_price: { value }
                    }
                }, type_id, variants, small_image: { url: img }, name
            }
        } = instance.props;

        if (type_id === PRODUCT_TYPE.configurable) {
            const configurableVariantIndex = instance.getConfigurableVariantIndex(sku, variants);

            if (!configurableVariantIndex) {
                return;
            }
        }

        eventTrack(ADD_TO_CART, {
            'Product Name': name,
            Size: options.length > 0 ? options.filter((i) => i.label === 'Size')[0]?.value : '',
            Color: options.length > 0 ? options.filter((i) => i.label === 'Color')[0]?.value : '',
            Quantity: quantity,
            Price: value,
            'Product Image': [img],
            'Product SKU': sku,
            Gender: gender || ''
        });

        handleGAEventsTracker('event', 'add_to_cart', {
            currency: 'INR',
            value,
            items: [{
                item_id: sku,
                item_name: name,
                currency: 'INR',
                index: 1,
                price: value,
                quantity
            }]
        });

        const state = getStore().getState();
        const cartData = state.CartReducer.cartTotals;
        const { items = [], subtotal } = cartData;

        eventTrack(CART_UPDATED, {
            'No. of Products': items.length,
            'Total Amount': subtotal,
            'Product Details': getCartProductsDetailsInArray(items)
        });
    });
};

export default {
    'Component/WishlistItem/Container': {
        'member-function': {
            addItemToCart
        }
    }
};
